import "./footerContact.css";
import { icons } from "../../assets/icons";

function FooterContact() {
  return (
    <div className="footer-contact-container">
      <div className="address-box">
        <ul>
          <li>
            <h4 className="title">PAYEPLAST PLASTİK SAN. VE TİC. A.Ş</h4>
          </li>
          <li>
            <img src={icons.mail} alt="icon" />
            <span>export@payeplast.com.tr</span>
          </li>
          <li>
            <img src={icons.phone} alt="icon" />
            <span>+90 224 484 31 94</span>
          </li>
          <li>
            <span>Fax </span>
            <span>+ 90 224 484 31 93</span>
          </li>
          <li>
            <a
              href="https://www.google.com/maps/place/Paye+Plast+Ekstr%C3%BCder/@40.1829278,28.8208502,18z/data=!3m1!4b1!4m6!3m5!1s0x14ca05d663473bdd:0x30e0efc3121695b1!8m2!3d40.1829258!4d28.8220905!16s%2Fg%2F11bychg44g?entry=ttu"
              target="_blank"
            >
              <img src={icons.map} alt="icon" />
              <span>
                Kayapa, KIRMIZI (530) CD NO:18, 16159 Organize Sanayi
                Bölgesi/Nilüfer/Bursa
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterContact;
