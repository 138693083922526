import "./footer.css";
import React, { useState } from "react";
import { icons } from "../../assets/icons/index";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContractsModal from "../Modals/ContractsModal/ContractsModal";
import { ModalContextValue } from "../../context/ModalContext";
import { contractsPdf } from "../../assets/pdf/index";

function Footer() {
  const { t } = useTranslation();
  const { openModal } = ModalContextValue();

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const handleOpenPrivacy = () => setOpenPrivacyPolicy(true);
  const handleClosePrivacy = () => setOpenPrivacyPolicy(false);

  const [openCookiePolicy, setOpenCookiePolicy] = useState(false);
  const handleOpenCookie = () => setOpenCookiePolicy(true);
  const handleCloseCookie = () => setOpenCookiePolicy(false);

  const [openTermsPolicy, setOpenTermsPolicy] = useState(false);
  const handleOpenTerms = () => setOpenTermsPolicy(true);
  const handleCloseTerms = () => setOpenTermsPolicy(false);

  return (
    <footer
      style={{
        zIndex: openModal ? -1 : 1,
      }}
      className="footer-container"
    >
      <div className="footer-content">
        <div className="address-box">
          <h4 className="title">PAYEPLAST PLASTİK SAN. VE TİC. A.Ş</h4>
          <ul>
            <li>
              <img src={icons.mail} alt="icon" />
              <span>export@payeplast.com.tr</span>
            </li>
            <li>
              <img src={icons.phone} alt="icon" />
              <span>+90 224 484 31 94</span>
            </li>
            <li>
              <span className="fax">Fax </span>
              <span>+ 90 224 484 31 93</span>
            </li>
            <li>
              <a
                href="https://www.google.com/maps/place/Paye+Plast+Ekstr%C3%BCder/@40.1829278,28.8208502,18z/data=!3m1!4b1!4m6!3m5!1s0x14ca05d663473bdd:0x30e0efc3121695b1!8m2!3d40.1829258!4d28.8220905!16s%2Fg%2F11bychg44g?entry=ttu"
                target="_blank"
              >
                <img src={icons.map} alt="icon" />
                <span>
                  Kayapa, KIRMIZI (530) CD NO:18, 16159 Organize Sanayi
                  Bölgesi/Nilüfer/Bursa
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div className="links-box">
          <div className="site-map">
            <h4 className="title">{t("footer_site_map")}</h4>
            <div className="site-map-content">
              <ul className="left">
                <li>
                  <Link to="/">{t("homepage")}</Link>
                </li>
                <li>
                  <Link to="/products">{t("products")}</Link>
                </li>
                <li>
                  <Link to="/gallery">{t("gallery")}</Link>
                </li>
              </ul>
              <ul className="right">
                <li>
                  <Link to="/about">{t("about")}</Link>
                </li>
                <li>
                  <Link to="/references">{t("references")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("contact")}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="contracts">
            <h5 className="title">{t("footer_contracts")}</h5>
            <ul>
              <li onClick={handleOpenPrivacy}>
                {t("footer_contract_privacy")}
              </li>
              <li onClick={handleOpenCookie}>{t("footer_contract_kvk")}</li>
              <li onClick={handleOpenTerms}>{t("footer_contract_usage")}</li>
            </ul>
            {openPrivacyPolicy && (
              <ContractsModal
                contractTitle={t("privacy_policy_title")}
                handleCloseModal={handleClosePrivacy}
                contractText={contractsPdf.cookiePolicy}
              />
            )}
            {openCookiePolicy && (
              <ContractsModal
                contractTitle={t("kvk_policy_title")}
                handleCloseModal={handleCloseCookie}
                contractText={contractsPdf.kvkPolicy}
              />
            )}
            {openTermsPolicy && (
              <ContractsModal
                contractTitle={t("terms_of_use_title")}
                handleCloseModal={handleCloseTerms}
                contractText={contractsPdf.termsPolicy}
              />
            )}
          </div>

          <div className="social-media">
            <h5 className="title">{t("footer_links")}</h5>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/paye-plast/"
                  target="_blank"
                >
                  <span>Linkedin</span> <img src={icons.linkedin} alt="icon" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/payeplast/" target="_blank">
                  <span>Instagram</span>{" "}
                  <img src={icons.instagram} alt="icon" />
                </a>
              </li>
              <li>
                <a href="https://wa.me/+905496165916" target="_blank">
                  <span>Whatsapp</span> <img src={icons.wp} alt="icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright-container">
        <p>{t("footer_copyright")}</p>
        <a href="https://oetech.org/" target="_blank">
          OETech Solutions
        </a>
      </div>
    </footer>
  );
}

export default Footer;
